@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  input[type='date'],
  input[type='datetime-local'] {
    cursor: pointer;
    padding-left: 25px;
    position: relative;
  }
  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='datetime-local']::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 5px;
    top: 10px;
    opacity: 1;
    display: block;
    background: url('./icons/calendar.svg') no-repeat;
    width: 20px;
    height: 20px;
  }
  .label {
    color: var(--Content-Tertiary, #a3a3a3);

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-left: 5px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .button {
    color: #2361a4;
    font-size: 14px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
    &:disabled {
      transition: all 0.3s ease;
      color: rgba(35, 97, 164, 0.7);
    }
  }
  .input {
    width: 100%;
    background: var(--Background-Surface, rgba(166, 174, 203, 0.1));

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.4px;
    color: var(--Content-Primary, #ebebeb);

    outline: none;
    margin-bottom: 10px;
    border-radius: 12px;
    //border: 1px solid #424242;
    margin-top: 7px;
    padding: 10px 16px;

    height: 54px;
    transition: all 0.3s ease;
    @media (max-width: 768px) {
      height: 44px;
      font-size: 15px;
    }
    &__error {
      border: 1.5px solid var(--Border-Negative, #ed603e);
      &:active,
      &:valid,
      &:hover,
      &:focus {
        transition: all 0.3s ease;
        color: #ebebeb;
        border: 1.5px solid var(--Border-Negative, #ed603e);
      }
    }
    &::placeholder {
      color: rgba(235, 235, 235, 0.7);
    }
    &:hover {
      transition: all 0.3s ease;
      background: rgba(166, 174, 203, 0.12);
    }
    &:active,
    &:focus {
      transition: all 0.3s ease;
      //border: 1px solid var(--Border-2, rgba(166, 174, 203, 0.12));
      background: rgba(166, 174, 203, 0.16);
    }
    &:disabled {
      transition: all 0.3s ease;
      background: rgba(66, 66, 66, 0.04);
      border: 1px solid #606060;
    }
  }

  .error {
    color: var(--Content-Secondary, #ccc);

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-left: 5px;
  }
}
